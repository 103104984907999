import getConfig from 'next/config'

import { useState, createRef, useEffect, useCallback, useMemo, useContext } from 'react';

import { Input, Skeleton, Table, Typography, InputGroup, Tooltip, Row, Col, Card, Toast } from '@douyinfe/semi-ui';
import { ColumnProps, Filter } from '@douyinfe/semi-ui/lib/es/table';
import { IconFile, IconClear, IconArrowLeft } from '@douyinfe/semi-icons';

import { trimEnd, debounce } from 'lodash';

import { useIntl, FormattedMessage } from 'react-intl'


import { useSmallScreen } from 'hooks/useResponsive';
import LoadingBrowser from './browser/loadingBrowser';
import { searchPapersByPrefix, IPaper } from 'apis/paper'
import { HeaderZIndex } from './globalLayout';
import { UserContext } from 'contexts/userContext';

const { publicRuntimeConfig } = getConfig()

type QuickIndexingTableProps = {
    loading: boolean,
    data: IPaper[],
    width: number | string
}

function QuickIndexingTable({ loading, data, width }: QuickIndexingTableProps): JSX.Element {
    const isSmallScreen = useSmallScreen()
    const [columns, setColumns] = useState<ColumnProps<IPaper>[]>([])
    const { Text } = Typography;
    const scroll = useMemo(() => ({ x: 300, y: 400 }), []);

    useEffect(() => {
        setColumns([
            {
                title: 'Paper',
                width: width,
                dataIndex: 'PaperName',
                render: (text, record: IPaper, index) => {
                    return (
                        <div>
                            <IconFile size="large" style={{ marginRight: 10 }} />
                            <Text link={{ href: `${publicRuntimeConfig.backendAPI[process.env.NODE_ENV]}/paper/download/${record.PaperID}`, target: "_blank" }}>
                                {record.PaperName}
                            </Text>
                        </div>
                    );
                },
            },
        ] as ColumnProps<IPaper>[])
    }, [Text, isSmallScreen, width])



    return (
        <Skeleton loading={loading} active placeholder={<LoadingBrowser rowNum={5} colNum={1} height={400} />}>
            <Table
                rowKey="PaperID"
                style={{ height: 450 }}
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={scroll}
            />
        </Skeleton>
    )
}

type QuickIndexingProps = {
    width?: number,
    showTooltip?: boolean,
    fromNav?: boolean,
    onNavClose?: () => void,
}

export default function QuickIndexing({ width = 450, showTooltip = true, fromNav = false, onNavClose }: QuickIndexingProps): JSX.Element {
    const intl = useIntl()
    const isSmallScreen = useSmallScreen()
    const [loading, setLoading] = useState(false)
    const [dropdownVisible, setDropDownVisible] = useState(false)
    const [inputCode, setInputCode] = useState("")
    const [inputSession, setInputSession] = useState("")
    const [inputType, setInputType] = useState("")
    const [inputNum, setInputNum] = useState("")
    const InputCodeRef = createRef<HTMLInputElement>()
    const InputSessionRef = createRef<HTMLInputElement>()
    const InputTypeRef = createRef<HTMLInputElement>()
    const InputNumRef = createRef<HTMLInputElement>()
    const ContainerRef = createRef<HTMLDivElement>()
    const { userData } = useContext(UserContext)

    const searchPrefix = trimEnd(`${inputCode}_${inputSession}_${inputType}_${inputNum}`, '_')
    const [dataSource, setData] = useState<IPaper[]>([])

    const debouncedFetch = useMemo(() => {
        return debounce((prefix: string) => {
            searchPapersByPrefix(prefix).then(res => {
                if (userData?.practiceMode)
                    setData(res.data.filter(paper => paper.PaperType != "ms"))
                else
                    setData(res.data)
            }).catch(err => {
                Toast.error({ content: intl.formatMessage({ defaultMessage: "Failed retrieving papers", id: 'FTRDl0' }) })
            }).finally(() => {
                setLoading(false)
            })
        }, 300)
    }, [intl, userData?.practiceMode])

    useEffect(() => {
        if (searchPrefix == "")
            return
        setLoading(true)
        debouncedFetch(searchPrefix)

    }, [debouncedFetch, searchPrefix])

    useEffect(() => {
        if (fromNav)
            InputCodeRef.current?.focus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromNav])

    const onInputCodeChange = useCallback((value: string, e: React.ChangeEvent<HTMLInputElement>) => {
        if (value.length <= 4) {
            setInputCode(value.toLocaleLowerCase())
            if (value.length === 4)
                InputSessionRef.current?.focus()
        }
        else
            InputSessionRef.current?.focus()
    }, [InputSessionRef])

    const onInputSessionChange = useCallback((value: string, e: React.ChangeEvent<HTMLInputElement>) => {
        if (value.length <= 3) {
            setInputSession(value.toLocaleLowerCase())
            if (value.length == 0) {
                InputCodeRef.current?.focus()
            }
            if (value.length === 3)
                InputTypeRef.current?.focus()
        }
        else
            InputTypeRef.current?.focus()
    }, [InputCodeRef, InputTypeRef])

    const onInputSessionKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (inputSession.length == 0 && e.key == 'Backspace') {
            InputCodeRef.current?.focus()
        }
    }, [InputCodeRef, inputSession.length])

    const onInputTypeChange = useCallback((value: string, e: React.ChangeEvent<HTMLInputElement>) => {
        if (value.length <= 2) {
            setInputType(value.toLocaleLowerCase())
            if (value.length == 0) {
                InputSessionRef.current?.focus()
            }
            if (value.length === 2)
                InputNumRef.current?.focus()
        }
        else
            InputNumRef.current?.focus()
    }, [InputNumRef, InputSessionRef])

    const onInputTypeKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (inputType.length == 0 && e.key == 'Backspace') {
            InputSessionRef.current?.focus()
        }
    }, [InputSessionRef, inputType.length])

    const onInputNumChange = useCallback((value: string, e: React.ChangeEvent<HTMLInputElement>) => {
        setInputNum(value.toLocaleLowerCase())
        if (value.length == 0) {
            InputTypeRef.current?.focus()
        }
    }, [InputTypeRef])

    const onInputNumKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (inputNum.length == 0 && e.key == 'Backspace') {
            InputTypeRef.current?.focus()
        }
    }, [InputTypeRef, inputNum.length])

    const onClickOutSide = useCallback((e) => {
        if (dropdownVisible && ContainerRef.current?.contains(e.target) == false) {
            setDropDownVisible(false)
            if (fromNav && searchPrefix.length == 0)
                onNavClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ContainerRef, onNavClose])

    useEffect(() => {
        window.addEventListener('click', onClickOutSide)
        return () => {
            window.removeEventListener('click', onClickOutSide)
        }
    }, [onClickOutSide])

    return (
        <div
            ref={ContainerRef}
            style={{
                display: 'flex',
            }}
        >
            {fromNav && isSmallScreen &&
                <IconArrowLeft
                    onClick={() => {
                        onNavClose()
                    }}
                    style={{
                        cursor: 'pointer',
                        alignSelf: 'center',
                        marginLeft: 10
                    }}
                />
            }
            <div style={{
                flexGrow: 1,
            }}>
                <InputGroup
                    style={{
                        display: 'flex',
                        position: 'relative',
                        borderRadius: 30,
                        justifyContent: 'center',
                        flexWrap: 'nowrap',
                    }}
                    onFocus={() => {
                        setDropDownVisible(true)
                    }}
                >
                    <Row style={{
                        display: 'inline-block',
                        borderRadius: 30,
                        maxWidth: width,
                        background: 'rgba(var(--semi-white), 1)',
                        transition: 'max-width 0.3s',
                        zIndex: fromNav ? HeaderZIndex : 2,
                    }}>
                        <Col span={8}>
                            <Tooltip
                                content={
                                    <div>
                                        <div><FormattedMessage defaultMessage="For example" id="qbXH6U" />,</div>
                                        <div><b>0625</b> (IGCSE Chemistry)</div>
                                        <div><b>9708</b> (AS & A Level Economics)</div>
                                    </div>
                                }
                                trigger={showTooltip ? 'focus' : 'custom'}
                                position='topLeft'
                            >
                                <Input
                                    className='quick-indexing-input-leftmost'
                                    inputStyle={{
                                        fontSize: isSmallScreen ? 12 : 14
                                    }}
                                    size={fromNav || isSmallScreen ? 'default' : 'large'}
                                    ref={InputCodeRef}
                                    placeholder='Subject Code'
                                    onChange={onInputCodeChange}
                                    value={inputCode}
                                />
                            </Tooltip>
                        </Col>
                        <Col span={6}>
                            <Tooltip content={
                                <div>
                                    <div><FormattedMessage defaultMessage="For example" id="qbXH6U" />,</div>
                                    <div><b>m16</b> (February/March 2016)</div>
                                    <div><b>s15</b> (May/June 2015)</div>
                                    <div><b>w20</b> (October/November 2020)</div>
                                </div>
                            }
                                trigger={showTooltip ? 'focus' : 'custom'}
                            >
                                <Input
                                    className='quick-indexing-input'
                                    inputStyle={{
                                        fontSize: isSmallScreen ? 12 : 14
                                    }}
                                    size={fromNav || isSmallScreen ? 'default' : 'large'}
                                    ref={InputSessionRef}
                                    placeholder='Session'
                                    onChange={onInputSessionChange}
                                    value={inputSession}
                                    onKeyDown={onInputSessionKeyDown}
                                />
                            </Tooltip>
                        </Col>
                        <Col span={4}>
                            <Tooltip
                                content={
                                    <div>
                                        <div><FormattedMessage defaultMessage="For example" id="qbXH6U" />,</div>
                                        <div><b>qp</b> (Question Paper)</div>
                                        <div><b>ms</b> (Mark Scheme)</div>
                                    </div>
                                }
                                trigger={showTooltip ? 'focus' : 'custom'}
                            >
                                <Input
                                    className='quick-indexing-input'
                                    inputStyle={{
                                        fontSize: isSmallScreen ? 12 : 14
                                    }}
                                    size={fromNav || isSmallScreen ? 'default' : 'large'}
                                    ref={InputTypeRef}
                                    placeholder='Type'
                                    onChange={onInputTypeChange}
                                    value={inputType}
                                    onKeyDown={onInputTypeKeyDown}
                                />
                            </Tooltip>
                        </Col>
                        <Col span={6}>
                            <Tooltip content={
                                <div>
                                    <div><FormattedMessage defaultMessage="For example" id="qbXH6U" />,</div>
                                    <div><b>1</b> (Paper 1)</div>
                                    <div><b>31</b> (Paper 3 Variant 1)</div>
                                </div>
                            }
                                trigger={showTooltip ? 'focus' : 'custom'}
                                position='topRight'
                            >
                                <Input
                                    className='quick-indexing-input-rightmost'
                                    inputStyle={{
                                        fontSize: isSmallScreen ? 12 : 14
                                    }}
                                    size={fromNav || isSmallScreen ? 'default' : 'large'}
                                    ref={InputNumRef}
                                    placeholder='Paper No.'
                                    onChange={onInputNumChange}
                                    value={inputNum}
                                    onKeyDown={onInputNumKeyDown}
                                />
                            </Tooltip>
                        </Col>
                    </Row>
                    <IconClear
                        onClick={() => {
                            setInputCode("")
                            setInputSession("")
                            setInputType("")
                            setInputNum("")
                        }}
                        style={{
                            cursor: 'pointer',
                            alignSelf: 'start',
                            zIndex: fromNav ? HeaderZIndex : 2,
                            opacity: searchPrefix.length > 0 ? 1 : 0,
                            transition: 'opacity 0.2s',
                            marginLeft: 5,
                        }}
                    />
                    {
                        (fromNav && isSmallScreen) ?
                            < div
                                style={{
                                    visibility: dropdownVisible ? 'visible' : 'hidden',
                                    boxShadow: 'rgb(0 0 0 / 30%) -1px 3px 5px -3px',
                                    borderRadius: 10,
                                    background: 'white',
                                    position: 'fixed',
                                    width: "100vw",
                                    top: 0,
                                    left: 0,
                                    zIndex: -1,
                                    paddingTop: 50,
                                }}
                            >
                                <QuickIndexingTable data={dataSource} loading={loading} width={"100vw"} />
                            </div>
                            :
                            <Card
                                shadows='always'
                                bodyStyle={{ padding: 0 }}
                                style={{
                                    visibility: dropdownVisible ? 'visible' : 'hidden',
                                    cursor: 'default',
                                    position: 'absolute',
                                    width: width + 30,
                                    top: -10,
                                    right: -5,
                                    zIndex: fromNav ? HeaderZIndex - 1 : 1,
                                    opacity: dropdownVisible ? 1 : 0,
                                    paddingTop: 50,
                                    overflow: 'visible',
                                    transition: 'opacity 0.3s',
                                }}
                            >
                                <QuickIndexingTable data={dataSource} loading={loading} width={isSmallScreen ? '90%' : width + 10} />
                            </Card>
                    }

                </InputGroup >
            </div>

        </div >
    )
}