/* eslint-disable @next/next/no-img-element */
import { useContext, useEffect, useState, useCallback } from 'react';

import { useRouter } from 'next/router'
import Head from 'next/head'
import Link from 'next/link'

import { useIntl, FormattedMessage } from 'react-intl'

import { Layout, Nav, Button, Toast, Avatar, Typography, Dropdown, Tooltip, Input, Switch } from '@douyinfe/semi-ui';
import { IconLikeHeart, IconArchive, IconLanguage, IconSearch, IconHome, IconMenu, IconExit, IconUser, IconTreeTriangleDown, IconFile, IconLock, IconUnlock, IconUserAdd, IconMail, IconBolt, IconHelpCircle } from '@douyinfe/semi-icons';

import { changeLanguage, changePracticeMode, ISupportedLanguageCode, userLogout } from 'apis/user';
import { useSmallScreen, useWindowSize } from 'hooks/useResponsive';
import { UserContext } from 'contexts/userContext';
import QuickIndexing from './quickIndexing';

export const HeaderZIndex = 3

function QuickIndexingToggle(): JSX.Element {
    const { isLoggedIn, } = useContext(UserContext)
    const isSmallScreen = useSmallScreen()
    const { width } = useWindowSize()
    const intl = useIntl()
    const [quickIndexingVisible, setQuickIndexingVisible] = useState(false)
    const [barWidth, setBarWidth] = useState(isSmallScreen ? 320 : 250)

    useEffect(() => {
        if (!isSmallScreen && quickIndexingVisible)
            setBarWidth(350)
    }, [isSmallScreen, quickIndexingVisible])

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: isSmallScreen ? (quickIndexingVisible ? 0 : 80) : null,
            right: isSmallScreen ? null : (isLoggedIn ? 130 : 250),
            width: quickIndexingVisible && isSmallScreen ? '100%' : null,
            height: 55,
            paddingTop: 15,
            backgroundColor: "var(--semi-color-bg-0)",
            zIndex: HeaderZIndex + 10
        }}>
            {quickIndexingVisible ?
                <QuickIndexing width={barWidth} showTooltip={false} fromNav onNavClose={() => {
                    setQuickIndexingVisible(false);
                    setBarWidth(isSmallScreen ? 320 : 250)
                }} />
                :
                <Input
                    style={{ width: isSmallScreen ? Math.min(150, width - 200) : 250, borderRadius: 30 }}
                    prefix={<IconSearch />}
                    placeholder={intl.formatMessage({ defaultMessage: 'Quick Indexing', id: 'XvFhvE' })}
                    onFocus={() => { setQuickIndexingVisible(true) }}
                />
            }
        </div>
    )
}

function ChangeLangButton(): JSX.Element {
    const intl = useIntl()
    const router = useRouter()
    const { pathname, asPath, query } = router
    const { isLoggedIn, refreshData } = useContext(UserContext)

    return (
        <Dropdown
            position="bottomRight"
            showTick
            render={
                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={() => {
                            if (isLoggedIn) {
                                changeLanguage("zh-CN").then(async () => {
                                    await refreshData()
                                }).then(() => {
                                    Toast.success({ content: intl.formatMessage({ defaultMessage: "Changes saved", id: 'nQKiSH' }) })
                                }).catch(() => {
                                    Toast.error({ content: intl.formatMessage({ defaultMessage: "Failed saving changes", id: 'ormK1o' }) })
                                })
                            }
                            router.push({ pathname, query }, asPath, { locale: 'zh-CN', scroll: false })
                        }}
                        active={router.locale == "zh-CN"}>
                        简体中文
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => {
                            if (isLoggedIn) {
                                changeLanguage("en-US").then(async () => {
                                    await refreshData()
                                }).then(() => {
                                    Toast.success({ content: intl.formatMessage({ defaultMessage: "Changes saved", id: 'nQKiSH' }) })
                                }).catch(() => {
                                    Toast.error({ content: intl.formatMessage({ defaultMessage: "Failed saving changes", id: 'ormK1o' }) })
                                })
                            }
                            router.push({ pathname, query }, asPath, { locale: 'en-US', scroll: false })
                        }}
                        active={router.locale == "en-US"}>
                        English
                    </Dropdown.Item>
                </Dropdown.Menu>
            }
        >
            <Button
                theme="borderless"
                icon={<IconLanguage size="large" />}
                style={{
                    color: 'var(--semi-color-text-2)',
                    margin: '0 12px',
                }}
            />
        </Dropdown>
    )
}

function DesktopNav(): JSX.Element {
    const router = useRouter()
    const intl = useIntl()
    const [userCenterVisible, setUserCenterVisible] = useState(false)
    const { isLoggedIn, userData, hasEBookAccess, localLogOut, refreshData } = useContext(UserContext)

    const toggleUserCenterDropDown = useCallback(() => {
        setUserCenterVisible(prev => !prev)
    }, [])

    const hideUserCenterDropDown = useCallback(() => {
        setUserCenterVisible(false)
    }, [])

    const logOut = useCallback(() => {
        userLogout().then(res => {
            router.push("/")
            hideUserCenterDropDown()
            localLogOut()
            Toast.success({ content: intl.formatMessage({ defaultMessage: "Successfully logged out", id: '90ti0A' }) })
        }).catch(err => {
            Toast.error({ content: intl.formatMessage({ defaultMessage: "Not logged in", id: '3pLtbV' }) })
        })
    }, [hideUserCenterDropDown, intl, localLogOut, router])

    const togglePracticeMode = useCallback((checked) => {
        changePracticeMode(checked).then(() => {
            refreshData()
            Toast.success({ content: intl.formatMessage({ defaultMessage: "Practice mode changed successfully", id: 'K8PrOe' }) })
        }).catch(() => {
            Toast.error({ content: intl.formatMessage({ defaultMessage: "Failed changing practice mode", id: 'WbT+yR' }) })
        })
    }, [intl, refreshData])

    return (
        <Nav
            mode="horizontal"
            header={
                <Link href={"/"}>
                    <a><img height={45} width={45} src="https://mfpapers.oss-cn-hangzhou.aliyuncs.com/img/logo.png" alt='logo' /></a>
                </Link>
            }
            footer={
                <>
                    <ChangeLangButton />
                    {
                        isLoggedIn ?
                            <Dropdown
                                visible={userCenterVisible}
                                onClickOutSide={hideUserCenterDropDown}
                                spacing={10}
                                trigger={'custom'}
                                position="bottomRight"
                                render={
                                    <Dropdown.Menu style={{ width: 220 }}>
                                        <Typography.Title heading={6} ellipsis={{ showTooltip: true }} style={{ textAlign: "center", padding: "5px" }}>
                                            @{userData.userName}
                                        </Typography.Title>
                                        <Dropdown.Divider />
                                        <Dropdown.Item
                                            icon={<IconBolt />}
                                            style={{
                                                cursor: 'default',
                                            }}

                                        >
                                            {intl.formatMessage({ defaultMessage: 'Practice Mode', id: 'gd2uK8' })}
                                            <Tooltip content={intl.formatMessage({ defaultMessage: 'Toggle to globally hide mark schemes', id: 'mL1ern' })} position="topLeft">
                                                <IconHelpCircle style={{ fontSize: 14, marginLeft: 8, cursor: 'pointer' }} />
                                            </Tooltip>
                                            <Switch style={{ marginLeft: 'auto' }} checked={userData.practiceMode} onChange={togglePracticeMode} />
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Link href="/account">
                                            <a style={{ display: 'flex', gap: 8 }}>
                                                <Dropdown.Item style={{ width: "100%" }}>
                                                    <IconUser />
                                                    {intl.formatMessage({ defaultMessage: 'Account', id: 'TwyMau' })}
                                                </Dropdown.Item>
                                            </a>
                                        </Link>
                                        <Dropdown.Item icon={<IconExit />} onClick={logOut}> {intl.formatMessage({ defaultMessage: 'Log Out', id: 'H0JBH6' })}</Dropdown.Item>
                                    </Dropdown.Menu>
                                }
                            >
                                <Avatar
                                    hoverMask={
                                        <div style={{
                                            backgroundColor: 'var(--semi-color-overlay-bg)',
                                            height: '100%',
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }} >
                                            <IconTreeTriangleDown />
                                        </div>
                                    }
                                    color={"purple"}
                                    size="default"
                                    onClick={toggleUserCenterDropDown}
                                >
                                    {userData.userName[0].toUpperCase()}
                                </Avatar>

                            </Dropdown>
                            :
                            <>
                                <Link href="/login">
                                    <a>
                                        <Button type='tertiary' size='large' style={{ marginRight: 8 }}>
                                            <FormattedMessage
                                                id="KZ2qjh"
                                                description="log in"
                                                defaultMessage="Log In"
                                            />
                                        </Button>
                                    </a>
                                </Link>
                                <Link href="/signup">
                                    <a>
                                        <Button theme='solid' type='primary' size='large'>
                                            <FormattedMessage
                                                id="ZST44O"
                                                description="sign up"
                                                defaultMessage="Sign Up"
                                            />
                                        </Button>
                                    </a>
                                </Link>
                            </>
                    }

                </>
            }
        >
            <Link href={"/"}><a><Nav.Item style={{ fontWeight: 600 }} icon={<IconHome size="large" />} text={intl.formatMessage({ defaultMessage: 'Home', id: 'nulrMy', description: 'home' })}></Nav.Item></a></Link>
            <Nav.Sub text={intl.formatMessage({ defaultMessage: 'Papers', id: 'QOw54M', description: 'papers' })} icon={<IconArchive size="large" />}>
                <Link href={"/browser?programID=1"}><a><Nav.Item text="Cambridge IGCSE" /></a></Link>
                <Link href={"/browser?programID=2"}><a><Nav.Item text="Cambridge AS & A Levels" /></a></Link>
            </Nav.Sub>
            {hasEBookAccess ?
                <Link href={"/ebooks"}>
                    <a >
                        <Nav.Item style={{ fontWeight: 600 }}>
                            <IconFile size="large" style={{ marginRight: 8 }} />
                            {intl.formatMessage({ defaultMessage: 'Ebooks', id: 'fCB0ub' })}
                            <IconUnlock style={{
                                fontSize: 12,
                                color: 'var(--semi-color-tertiary)',
                                marginLeft: 3
                            }} />
                        </Nav.Item>
                    </a>
                </Link > :
                <Nav.Item style={{ fontWeight: 600 }} disabled>
                    <IconFile size="large" style={{ marginRight: 8 }} />
                    <Tooltip content={(() => {
                        if (!isLoggedIn)
                            return intl.formatMessage({ defaultMessage: 'Log in to unlock ebooks', id: 'k0Sjuw' })
                        if (!hasEBookAccess)
                            return intl.formatMessage({ defaultMessage: 'Ebooks only avaiable to specific regions', id: 'MKZHCN' })
                        return <></>
                    })()}>
                        {intl.formatMessage({ defaultMessage: 'Ebooks', id: 'fCB0ub' })}
                    </Tooltip>
                    <IconLock style={{
                        fontSize: 12,
                        color: 'var(--semi-color-tertiary-disabled)',
                        marginLeft: 3
                    }} />
                </Nav.Item>
            }
        </Nav>
    )
}

function MobileNav(): JSX.Element {
    const router = useRouter()
    const intl = useIntl()
    const { isLoggedIn, userData, hasEBookAccess, localLogOut, refreshData } = useContext(UserContext)
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const logOut = useCallback(() => {
        userLogout().then(res => {
            router.push("/")
            localLogOut()
            Toast.success({ content: intl.formatMessage({ defaultMessage: "Successfully logged out", id: '90ti0A' }) })
        }).catch(err => {
            Toast.error({ content: intl.formatMessage({ defaultMessage: "Not logged in", id: '3pLtbV' }) })
        })
    }, [intl, localLogOut, router])

    const togglePracticeMode = useCallback((checked) => {
        changePracticeMode(checked).then(() => {
            refreshData()
            Toast.success({ content: intl.formatMessage({ defaultMessage: "Practice mode changed successfully", id: 'K8PrOe' }) })
        }).catch(() => {
            Toast.error({ content: intl.formatMessage({ defaultMessage: "Failed changing practice mode", id: 'WbT+yR' }) })
        })
    }, [intl, refreshData])

    const handleRouteStart = useCallback(() => {
        setSidebarVisible(false)
    }, [])

    useEffect(() => {
        router.events.on("routeChangeStart", handleRouteStart)

        return () => {
            router.events.off("routeChangeStart", handleRouteStart)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Nav
                mode="horizontal"
                header={<Link href="/" ><a style={{ zIndex: HeaderZIndex + 1 }}><img height={45} width={45} src="https://mfpapers.oss-cn-hangzhou.aliyuncs.com/img/logo.png" alt='logo' /></a></Link>}
                footer={
                    <>
                        <ChangeLangButton />
                        <Button
                            theme="borderless"
                            icon={<IconMenu size="large" />}
                            onClick={() => { setSidebarVisible(!sidebarVisible); }}
                            style={{
                                color: 'var(--semi-color-text-2)',
                                marginRight: '12px',
                            }}
                        />
                    </>
                }
            />
            <div
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 60,
                    width: '100%',
                    display: sidebarVisible ? 'block' : 'none',
                    background: 'white',
                    paddingBottom: 15,
                    boxShadow: '-1px 3px 5px -3px rgba(0, 0, 0, .3)',
                    borderBottom: '1px solid var(--semi-color-border)'
                }}
            >
                <Nav
                    mode='vertical'
                    style={{
                        width: "100%",
                        borderRight: 'none',
                    }}
                >
                    <Link href={"/"}><a><Nav.Item style={{ fontWeight: 600, padding: "8px 12px", marginBottom: 8 }} icon={<IconHome size="large" />} text={intl.formatMessage({ defaultMessage: 'Home', id: 'nulrMy', description: 'home' })}></Nav.Item></a></Link>
                    <Nav.Sub itemKey={'Papers'} text={intl.formatMessage({ defaultMessage: 'Papers', id: 'QOw54M', description: 'papers' })} icon={<IconArchive size="large" />}>
                        <Link href={"/browser?programID=1"}><a><Nav.Item text="Cambridge IGCSE" /></a></Link>
                        <Link href={"/browser?programID=2"}><a><Nav.Item text="Cambridge AS & A Levels" /></a></Link>
                    </Nav.Sub>
                    {hasEBookAccess ?
                        <Link href={"/ebooks"}><a>
                            <Nav.Item style={{
                                fontWeight: 600,
                                padding: "8px 12px",
                                marginBottom: 8,
                                borderBottom: '1px solid var(--semi-color-fill-2)'
                            }}
                                icon={<IconFile size="large" />}
                                text={
                                    <div style={{ display: 'flex' }}>
                                        {intl.formatMessage({ defaultMessage: 'Ebooks', id: 'fCB0ub' })}
                                        <IconUnlock style={{
                                            fontSize: 12,
                                            color: 'var(--semi-color-tertiary-tertiary)',
                                            marginLeft: 3
                                        }} />
                                    </div>
                                } />
                        </a></Link>
                        :
                        <Nav.Item
                            style={{
                                padding: "8px 12px",
                                marginBottom: 8,
                                borderBottom: '1px solid var(--semi-color-fill-2)'
                            }}
                            disabled={!hasEBookAccess}
                            icon={<IconFile size="large" />}
                            text={
                                <div style={{ display: 'flex' }}>
                                    <Tooltip content={(() => {
                                        if (!isLoggedIn)
                                            return intl.formatMessage({ defaultMessage: 'Log in to unlock ebooks', id: 'k0Sjuw' })
                                        if (!hasEBookAccess)
                                            return intl.formatMessage({ defaultMessage: 'Ebooks only avaiable to specific regions', id: 'MKZHCN' })
                                        return <></>
                                    })()}>
                                        {intl.formatMessage({ defaultMessage: 'Ebooks', id: 'fCB0ub' })}
                                    </Tooltip>
                                    <IconLock style={{
                                        fontSize: 12,
                                        color: 'var(--semi-color-tertiary-disabled)',
                                        marginLeft: 3
                                    }} />
                                </div>
                            }
                        />
                    }
                    {
                        isLoggedIn ?
                            <>
                                <Link href={"account"}><a>
                                    <Nav.Item
                                        style={{ fontWeight: 600, padding: "8px 12px", marginBottom: 8 }}
                                        icon={
                                            <Avatar
                                                color={"purple"}
                                                size="extra-extra-small"
                                            >
                                                {userData.userName[0].toUpperCase()}
                                            </Avatar>
                                        }
                                        text={<b>@{userData.userName}</b>}>
                                    </Nav.Item>
                                </a></Link>
                                <Nav.Item
                                    style={{
                                        height: 'auto'
                                    }}
                                    icon={<IconBolt />}
                                    text={
                                        <div style={{ display: 'flex' }}>
                                            {intl.formatMessage({ defaultMessage: 'Practice Mode', id: 'gd2uK8' })}
                                            <Tooltip content={intl.formatMessage({ defaultMessage: 'Toggle to globally hide mark schemes', id: 'mL1ern' })} position="topLeft">
                                                <IconHelpCircle style={{ fontSize: 14, marginLeft: 8, cursor: 'pointer' }} />
                                            </Tooltip>
                                            <Switch size='default' style={{ marginLeft: 20, alignSelf: 'center' }} checked={userData.practiceMode} onChange={togglePracticeMode} />
                                        </div>
                                    }
                                />
                                <Nav.Item
                                    onClick={logOut}
                                    icon={<IconExit />}
                                    text={intl.formatMessage({ defaultMessage: 'Log Out', id: 'H0JBH6' })}
                                />
                            </> :
                            <>
                                <Link href={"login"}><a>
                                    <Nav.Item
                                        style={{ fontWeight: 600, padding: "8px 12px", marginBottom: 8 }}
                                        icon={<IconUnlock />}
                                        text={intl.formatMessage({ defaultMessage: 'Log In', id: 'r2Jjms' })}>
                                    </Nav.Item>
                                </a></Link>
                                <Link href={"signup"}><a>
                                    <Nav.Item
                                        style={{ fontWeight: 600, padding: "8px 12px", marginBottom: 8 }}
                                        icon={<IconUserAdd />}
                                        text={intl.formatMessage({ defaultMessage: 'Sign Up', id: '39AHJm' })}>
                                    </Nav.Item>
                                </a></Link>
                            </>
                    }
                </Nav>
            </div>
        </>

    )

}

function GlobalLayout({ children, title }: React.PropsWithChildren<{ title: string }>): JSX.Element {
    const isSmallScreen = useSmallScreen()
    const intl = useIntl()
    const { Header, Footer, Content } = Layout;

    return (
        <>
            <Head>
                <title>{title}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
                <link rel="apple-touch-icon" href="https://mfpapers.oss-cn-hangzhou.aliyuncs.com/img/logo.png"></link>
                <link data-react-helmet="true" rel="alternate" hrefLang="zh-hans" href="https://www.mfpapers.com/zh-CN"></link>
            </Head>
            <Layout style={{ border: '1px solid var(--semi-color-border)' }}>
                <Header style={{ background: 'transparent', position: 'fixed', top: 0, width: '100%', zIndex: HeaderZIndex }}>
                    {isSmallScreen ? <MobileNav /> : <DesktopNav />}
                    <QuickIndexingToggle />
                </Header>
                <Content
                    style={{
                        marginTop: 60,
                        backgroundColor: 'var(--semi-color-bg-0)',
                    }}
                >
                    {children}
                </Content>
                <Footer
                    style={{
                        display: 'flex',
                        flexDirection: isSmallScreen ? 'column' : 'row-reverse',
                        justifyContent: 'space-around',
                        gap: 50,
                        textAlign: isSmallScreen ? "center" : 'start',
                        padding: '40px',
                        color: 'var(--semi-color-text-2)',
                        backgroundColor: 'rgba(var(--semi-grey-0), 1)',
                    }}
                >
                    <div>
                        <div style={{
                            display: 'flex',
                            height: 50,
                            lineHeight: '50px',
                            justifyContent: 'space-evenly',
                            gap: 20
                        }}>
                            <Link href='/privacy'>
                                <a style={{
                                    color: 'var(--semi-color-text-1)',
                                    fontSize: 16,
                                    fontWeight: 500,
                                }}>
                                    <IconLock style={{ marginRight: 4, verticalAlign: 'middle' }} />
                                    {intl.formatMessage({ defaultMessage: 'Privacy', id: 'cXBJ7U' })}
                                </a>
                            </Link>
                            <a href='mailto:contact@mfpapers.com' style={{
                                color: 'var(--semi-color-text-1)',
                                fontSize: 16,
                                fontWeight: 500
                            }}>
                                <IconMail style={{ marginRight: 4, verticalAlign: 'middle' }} />
                                {intl.formatMessage({ defaultMessage: 'Contact', id: 'zFegDD' })}
                            </a>
                        </div>
                        <div style={{ marginTop: 20 }}>Made with <IconLikeHeart style={{ color: "rgba(var(--semi-red-6), 1)" }} /> by ZJG Ivyers</div>
                    </div>
                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: isSmallScreen ? 'center' : 'start'
                        }}>
                            <img height={50} width={50} src="https://mfpapers.oss-cn-hangzhou.aliyuncs.com/img/logo.png" alt='logo' />
                            <Typography.Title style={{
                                fontWeight: 700,
                                fontSize: '24px',
                                marginLeft: 10,
                            }} >
                                MFPAPERS
                            </Typography.Title>
                        </div>
                        <div style={{ marginTop: 20 }}>Copyright © 2022. All Rights Reserved.</div>
                    </div>
                </Footer>
            </Layout>
        </>
    )
}

export default GlobalLayout