import React from 'react';

import type { NextPage } from 'next'

import { Skeleton, Table } from '@douyinfe/semi-ui';

type Props = {
    rowNum: number
    colNum: number
    height: number
}

const LoadingBrowser: NextPage<Props> = ({ rowNum, colNum, height }) => {
    const rows = Array.from({ length: rowNum }, (_, i) => i + 1)
    const columns = Array.from({ length: colNum }, (_, i) => i + 1)

    const skData = {
        columns: columns.map(key => ({
            title: <Skeleton.Title style={{ width: '0' }} />,
            dataIndex: `${key}`
        })),
        dataSource: rows.map(key => {
            const item = { key: key };
            columns.forEach(i => {
                const width = 50 * i;
                item[i] = <Skeleton.Paragraph style={{ width: 200 }} rows={1} />;
            });
            return item;
        })
    };

    return (
        <Table
            style={{ height: height }}
            columns={skData.columns}
            dataSource={skData.dataSource}
            pagination={false}
        />
    );
};

export default LoadingBrowser