import { request } from "apis/request";

export type IProgram = {
    ProgramID: number
    ProgramName: string
}

export type ISubject = {
    Program: IProgram
    ProgramID: number
    SubjectCode: string
    SubjectID: number
    SubjectName: string
}

export type IPaper = {
    PaperID: number
    PaperName: string
    PaperType: string
    PaperYear: number
    FileExt: string
    FileSize: number
    FileURL: string
    Subject: ISubject
    SubjectID: number
    UpdatedAt: string
}

export type IYear = {
    Year: number
}

export function getPrograms() {
    return request.get<IProgram[]>('/program')
}

export function getProgramByID(programID: number) {
    return request.get<IProgram>(`/program/${programID}`)
}

export function getSubjectsByProgram(programID: number) {
    return request.get<ISubject[]>(`/program/${programID}/subject`)
}

export function getSubjectByID(programID: number, subjectID: number) {
    return request.get<ISubject>(`/program/${programID}/subject/${subjectID}`)
}

export function getYearsBySubject(programID: number, subjectID: number) {
    return request.get<number[]>(`/program/${programID}/subject/${subjectID}/paper/year`).then(res => res.data.map(item => ({ Year: item } as IYear)))
}

export function getPaperBySubjectYear(programID: number, subjectID: number, year: string) {
    return request.get<IPaper[]>(`/program/${programID}/subject/${subjectID}/paper/year/${year}`)
}

export function searchPapersByPrefix(prefix: string) {
    return request.get<IPaper[]>(`/paper/prefix/${prefix}`)
}